import React, { useMemo, useState } from 'react'
import { ProductHeaderTop, ProductHeaderTopInner } from './styles'
import { BespokeLogo } from './bespoke-logo'
import { BespokeMenus } from './menus'

export const BespokeInnerMenu = () => {
  return (
    <ProductHeaderTop>
      <ProductHeaderTopInner>
        <BespokeLogo />
      </ProductHeaderTopInner>
      <ProductHeaderTopInner>
        <BespokeMenus />
      </ProductHeaderTopInner>
    </ProductHeaderTop>
  )
}
